.card-list-alkol {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;/* Kartları yatayda ortala */
  gap: 20px; /* Kartlar arasındaki boşluk */
  width: 90%;
  margin: 20px auto
}
.card-list-alkol h1 {
  margin-top: 50px;
}
.card-list-alkol h2 {
  margin-top: 30px;
  font-family: "Times New Roman", Times, serif;
  color: #ffffff;
  font-size: 40px;
  text-align: center;
}

.custom-card-alkol {
  
  width: 100%; /* Sets a fixed width for cards, adjust as needed */
  max-width: 600px; /* Limits the maximum width for larger screens */
  display: flex;
  align-items: center;
  border: 3px solid #ccc;
  background-color: #6797c8a2;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 100px;
  text-decoration: none;
  color: white;

}

.custom-card-alkol img {
  width: 110px;
  height: 100px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.custom-card-alkol:hover {
  transform: scale(1.05); /* Kartın boyutunu hafifçe büyüt */
}

.card-content-admin  {
  padding: 10px;
  text-align: center;
  color: white;
  font-family: "Times New Roman", Times, serif;
}
.card-content-admin h3 {
  font-size: 24px;
  text-align: left;
  margin-left: 10px;
}


