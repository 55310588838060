.cardListContentFirst {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-left: 100px;
}
.cardListContentSecond {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-left: 343px;
  margin-right: 236px;
  margin-top: 50px;
}
.cardListTitle {
  margin-top: 60px;
  font-size: 30px;
}
.cardList h2 {
  margin-top: 30px;
  font-family: "Times New Roman", Times, serif;
  color: #ffffff;
  font-size: 40px;
  text-align: center;
}

.customCard {
  width: 300px; /* Kartların genişliği */
  background-color: #6797c870;
  display: flex;
  flex-direction: column;
  align-items: center; /* Kart içeriğini dikeyde ortala */
  text-align: center; /* Kart içeriğini yatayda ortala */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-decoration: none; /* Linkin altını çizme */
  color: inherit; /* Link rengini kalıtım al */
  transition: transform 0.3s ease; /* Kart üzerine gelince biraz büyütme animasyonu */
}

.customCard img {
  width: 100%;
  height: 250px; /* Resim yüksekliği */
  object-fit: cover;
}

.customCard:hover {
  transform: scale(1.05); /* Kartın boyutunu hafifçe büyüt */
}

.cardContent h3 {
  margin-bottom: 30px;
  font-size: 30px;
  color: #ffffff;
}

.cardContent p {
  margin-bottom: 15px;
}

@media only screen and (max-width: 800px) {
  .cardListContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-left: 100px;
  }
  .cardListTitle {
    margin-top: 60px;
    font-size: 20px;
  }
  .customCard {
    margin-right: 100px;
    width: 250px; /* Kartların genişliği */
    background-color: #6797c870;
    display: flex;
    flex-direction: column;
    align-items: center; /* Kart içeriğini dikeyde ortala */
    text-align: center; /* Kart içeriğini yatayda ortala */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-decoration: none; /* Linkin altını çizme */
    color: inherit; /* Link rengini kalıtım al */
    transition: transform 0.3s ease; /* Kart üzerine gelince biraz büyütme animasyonu */
  }
}
