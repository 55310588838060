.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  
  .card-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 30px;
    
  }