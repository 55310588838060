/* BalikDetailPage.module.css */
.balikMenuContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #6797c870;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  background-color: white; /* Light background color */
  color: #333; /* Dark text color for contrast */
}

.loading .spinner {
  border: 8px solid #f3f3f3; /* Light grey background */
  border-top: 8px solid #3498db; /* Blue spinner color */
  border-radius: 50%;
  width: 50px; /* Size of the spinner */
  height: 50px; /* Size of the spinner */
  animation: spin 1s linear infinite; /* Spinning animation */
}

.loading .message {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  color: #333; /* Dark text color */
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .loading .spinner {
    width: 40px; /* Smaller spinner for mobile */
    height: 40px; /* Smaller spinner for mobile */
  }

  .loading .message {
    font-size: 20px; /* Smaller text size for mobile */
  }
}
.heading {
  font-size: 32px;
  margin-bottom: 20px;
  color: #fcf7f7;
  text-align: center;
  border-bottom: 2px solid #ff5a5f;
  padding-bottom: 10px;
  width: 100%;
}

.menuImage {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 12px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.menuImage:hover {
  transform: scale(1.05);
}

.menuYoutube {
  width: 100px;
  height: 50px;
  cursor: pointer;
  margin: 10px 0;
  transition: transform 0.3s ease;
}

.menuYoutube:hover {
  transform: scale(1.1);
}

.text {
  font-size: 30px;
  color: #ebe4e4;
  margin: 10px 0;
  text-align: center;
}
.price {
  font-size: 30px;
  color: #e4e9eb;
  margin: 10px 0;
  text-align: center;
}
.siparisver {
  display: flex;
  align-items: center;
}

.menuSepet {
  margin-left: 10px;
  margin-top: 20px;
  width: 55px;
  height: 55px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.menuSepet:hover {
  transform: scale(1.1);
}

.noteInput {
  width: 100%;
  max-width: 400px;
  height: 100px;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 768px) {
  .balikMenuContainer {
    padding: 10px;
  }

  .heading {
    font-size: 28px;
  }

  .text {
    font-size: 16px;
  }

  .menuYoutube {
    width: 80px;
    height: 50px;
  }

  .buttonInput {
    width: 50px;
    padding: 8px;
    font-size: 14px;
  }

  .noteInput {
    width: 100%;
    max-width: 300px;
    height: 80px;
    padding: 8px;
    font-size: 14px;
  }
}
