.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #6797c8a2; /* Arka plan rengi */
  border-bottom: 1px solid #ced4da; /* Alt kenarlık */
}
.navbar-back {
  margin-left: 10px;
  cursor: pointer;
}
.navbar-back img {
  width: 40px;
}
.navbar-sepet {
  cursor: pointer; /* Farenin üzerine gelince el simgesi */
}

.navbar-sepet img {
  width: 80px; /* Resmin genişliği */
  /* Resmin boyutları otomatik ayarlanacak */
}
