.card-list {
  display: flex;
  flex-direction: column; /* Aligns items vertically */
  align-items: center; /* Centers items horizontally */
  gap: 20px; /* Space between cards */
  margin: 20px auto; /* Center the card list with auto margins */
  max-width: 1200px; /* Maximum width to keep content centered */
  width: 90%;
}

.card-list h1 {
  color: white;
  font-size: 40px;
  font-family: "Times New Roman", Times, serif;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.custom-card {
  width: 100%; /* Sets a fixed width for cards, adjust as needed */
  max-width: 600px; /* Limits the maximum width for larger screens */
  display: flex;
  align-items: center;
  border: 3px solid #ccc;
  background-color: #6797c8a2;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 100px;
  text-decoration: none;
  color: inherit;
}

.custom-card img {
  width: 110px;
  height: 100px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.card-content {
  padding: 10px;
  text-align: center;
  color: white;
  font-family: "Times New Roman", Times, serif;
  margin-bottom: 10px;
}

.card-content h3 {
  margin-bottom: 10px;
  font-size: 24px;
  text-align: left;
  margin-left: 10px;
}



.card-content button {
  padding: 8px 16px;
  background-color: #01060c;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card-content button:hover {
  background-color: #030609;
}
