/* Ana Container */
.stock-page-container {
    margin: 20px;
    padding: 20px;
    background-color: #f7f9fc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Başlık */
  .stock-page-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  /* Tablo */
  .stock-table-container {
    margin-bottom: 20px;
  }
  
  .stock-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .stock-table thead {
    background-color: #007bff;
    color: white;
  }
  
  .stock-table th, .stock-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .stock-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .stock-table tr:hover {
    background-color: #eaeaea;
  }
  
  /* Form Başlığı */
  .stock-form-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #007bff;
  }
  
  /* Select Kutusu */
  .stock-select-container {
    margin-bottom: 10px;
  }
  
  .stock-select {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    background-color: #fff;
  }
  
  /* Input Kutuları */
  .stock-input-container {
    margin-bottom: 10px;
  }
  
  .stock-input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  /* Butonlar */
  .stock-button-container {
    text-align: center;
  }
  
  .stock-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .stock-button:hover {
    background-color: #0056b3;
  }
  
  /* Düzenle ve Sil Butonları */
  .stock-action-buttons {
    display: flex;
    gap: 10px;
  }
  
  .stock-edit-button, .stock-delete-button {
    padding: 5px 10px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .stock-edit-button {
    background-color: #28a745;
    color: white;
  }
  
  .stock-edit-button:hover {
    background-color: #218838;
  }
  
  .stock-delete-button {
    background-color: #dc3545;
    color: white;
  }
  
  .stock-delete-button:hover {
    background-color: #c82333;
  }
