/* AdminSiparis.css */

.admin-siparis-container {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-siparis-title {
  text-align: center;
  font-size: 36px;
  margin-bottom: 20px;
  color: #333;
  font-family: 'Arial', sans-serif;
}

.masa-siparis-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.masa-no {
  text-align: center;
  font-size: 24px;
  margin: 10px 0;
  color: #555;
}

.siparis-listesi {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.siparis-detay {
  padding: 15px;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: column;
}

.isim,
.adet,
.fiyat {
  margin: 5px 0;
  font-size: 16px;
  color: #444;
}

.adisyon-ucreti {
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  color: #333;
}

.garson-info {
  margin-top: 10px;
  font-size: 16px;
  color: #666;
}

.button-admin {
  background-color: #e74c3c;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 16px;
  font-weight: bold;
}

.button-admin:hover {
  background-color: #c0392b;
  transform: scale(1.05);
}

.button-admin:focus {
  outline: none;
}
