/* Genel Stil */
.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #3c6085; /* Arka plan rengini değiştirdim */
}

.sepet-container {
  max-width: 1000px;
  background: linear-gradient(145deg, #6b83a3, #4c5f73);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 15px; /* Köşeleri yuvarladım */
  color: #ffffff;
  margin-bottom: 30px;
  
}

h1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 30px;
  color: #fff;
  font-weight: bold;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.siparis {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  background-color: #ffffff33;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px;
  max-height: 200px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.toplam-fiyat-container {
  text-align: center;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #ffffff33;
  width: 95%;
}

.siparis:hover {
  transform: translateY(-5px);
}

.foto {
  height: 70px;
  border-radius: 10px; /* Resmin köşelerini yuvarladım */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  margin-left: -9px;
  margin-right: 8px;
  margin-top: 4px;
}
.silButton{
  display: flex;
  justify-content: end;
  margin-right: -8px;
}
.yemekisim p {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  
}
.yemektotalprice{
  margin-left: -15px;
}

.yemekisim{
  width: 200px;
}

.yemekquantity {

  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.yemekquantity p{
  color: rgb(243, 243, 243);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 1px;
}
.artırmazaltma {
  display: flex;
  justify-content: center;
  align-items: center;
}

.artırmazaltma button {
  margin: 0 10px;
  width: 20px;
  height: 20px;
  background-color: #f53b57;
  color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0;
}

.artırmazaltma button:hover {
  background-color: #ff5e77;
}

.quantity {
  margin: 0 10px;
  font-size: 1.2rem;
  color: #fff;
}

.yemekfiyat, .yemektotalprice {
  width: 150px;
  text-align: center;
}
.yemektotalprice p{
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}

.yemekfiyat p {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: start;
  margin-left: 17px;
}

.silButton button {
  width: 46px;
  height: 33px;
  background-color: #ff4d4f;
  border: none;
  border-radius: 10%;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}

.silButton p{
  color: white;
}

.silButton button:hover {
  background-color: #ff7875;
}


.toplam-fiyat h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
}

.siparis-note textarea {
  width: 90%;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 1rem;
  resize: none;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #eef2f7;
  color: #333;
}

.siparisi-bitir button {
  width: 100%;
  height: 60px;
  background-color: #28a745;
  color: white;
  padding: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.5rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.siparisi-bitir button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.quanity-totalPrice-delete{
  display: flex;
  justify-content: center;
  align-items: center;
}
.yemekisim p{
  text-align: start;
}
/* Responsive Stil */
@media screen and (max-width: 768px) {
  
  .sepet-container {
    margin: 20 20px;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
  }

  .quanity-totalPrice-delete{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: -15px;
  }

  .siparis {
    
    text-align: center;
    max-height: none;
    width: 333px;
  }

  

  .yemekisim p {
    font-size: 1.2rem;
    text-align: start;
  }

  .artırmazaltma button {
    max-width: 30px;
    max-height: 30px;
  }

  .quantity p {
    font-size: 1.2rem;
  }

  .silButton button {
    max-width: 70px;
    max-height: 35px;
  }

  .siparisi-bitir button {
    width: 100%;
    padding: 20px;
    font-size: 1.2rem;
    height: auto;
  }
}
