.dashboard-container {
  text-align: center;
  padding: 20px;
}

.dashboard-container h1 {
  margin-bottom: 20px;
}

.dashboard-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-link {
  text-decoration: none;
  color: inherit;
  margin: 10px;
}

.dashboard-card {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
}

.dashboard-card:hover {
  background-color: #e0e0e0;
  transform: scale(1.02);
}

.dashboard-card h2 {
  margin-top: 0;
}

.dashboard-card p {
  color: #666;
}
/* Genel stil */
.ciro-container-all-hafta , .ciro-container-all-aylik , .ciro-container-all-gunluk{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

/* Başlık stili */
.ciro-header-hafta , .ciro-header-aylik , .ciro-header-gunluk{
  text-align: center;
  margin-bottom: 30px;
}

.ciro-header-hafta h1 , .ciro-header-aylik h1 , .ciro-header-gunluk h1{
  font-size: 2.5em;
  color: #4a90e2;
  margin: 0;
}

.ciro-summary-hafta , .ciro-summary-aylik , .ciro-summary-gunluk{
  margin-top: 10px;
}

.ciro-summary-hafta h2 , .ciro-summary-aylik h2 , .ciro-summary-gunluk h2{
  font-size: 30px;
  color: #36c43b;
  margin: 0;
  font-weight: 600;
}

/* İçerik konteyneri */
.ciro-container-hafta , .ciro-container-aylik , .ciro-summary-gunluk h2{
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}

/* Kategori seçici */
.category-selector-hafta , .category-selector-aylik , .category-selector-gunluk{
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.category-selector-hafta label , .category-selector-aylik label ,  .category-selector-gunluk label{
  font-size: 1.1em;
  color: #333;
  margin-right: 10px;
}

.category-selector-hafta select , .category-selector-aylik select , .category-selector-gunluk select{
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  transition: border 0.3s ease;
}

.category-selector-hafta select:hover,
.category-selector-hafta select:focus,
.category-selector-aylik select:hover,
.category-selector-aylik select:focus,
.category-selector-gunluk select:hover,
.category-selector-gunluk select:focus {
  border-color: #4a90e2;
  outline: none;
}

/* Grafikler ve içerik */
.ciro-chart-hafta , .ciro-chart-aylik , .ciro-chart-gunluk{
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  width: 90%;
  margin: auto;

}

.ciro-chart-hafta h2 , .ciro-chart-aylik h2 , .ciro-chart-gunluk h2{
  font-size: 1.5em;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}
.analiz-en-cok-hafta , .analiz-en-cok-aylik , .analiz-en-cok-gunluk{
  display: flex;
  align-items: center;
}
.en-cok-satan-kategori-sec-hafta, .en-cok-satan-kategori-sec-aylik , .en-cok-satan-kategori-sec-gunluk{
  display: flex;
  flex-direction: column;
  align-items: end;
  
}
/* En çok satan ürün */
.top-selling-item-hafta , .top-selling-item-aylik , .top-selling-item-gunluk{
  grid-column: 1 / -1;
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.top-selling-item-hafta h2 , .top-selling-item-aylik h2 , .top-selling-item-gunluk h2{
  font-size: 1.5em;
  color: #333;
  margin-bottom: 20px;
}

.top-selling-item-hafta p , .top-selling-item-aylik p , .top-selling-item-gunluk p{
  font-size: 1.1em;
  color: #666;
  margin: 5px 0;
}

.top-selling-item-hafta strong, .top-selling-item-aylik strong , .top-selling-item-gunluk strong {
  color: #4a90e2;
}

/* Tooltip ve Legend için stil ayarı */
.recharts-tooltip-wrapper,
.recharts-legend-wrapper {
  font-size: 0.9em;
}

/* Media Queries: Mobil görünüm için */
@media (max-width: 768px) {
  .ciro-container-hafta , .ciro-container-aylik , .ciro-container-gunluk{
    grid-template-columns: 1fr;
  }

  .ciro-chart-hafta , .ciro-chart-aylik , .ciro-chart-gunluk{
    padding: 15px;
  }

  .ciro-header-hafta h1 , .ciro-header-aylik h1 , .ciro-header-gunluk h1{
    font-size: 2em;
  }

  .ciro-summary-hafta h2 , .ciro-summary-aylik h2 , .ciro-summary-gunluk h2{
    font-size: 1.2em;
  }
}

