.balik-menu-container {
  max-width: max-content;
  margin: auto;
  padding: 20px;
}
.balik-menu-container h2 {

  font-family: "Times New Roman", Times, serif;
  color: #ffffff;
  font-size: 40px;
}
.menu-list {
  max-width: 100%;
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction:column;
  gap: 20px; /* Aralarda boşluk bırakmak için */
  justify-content: center;
  align-items: center;
}

.menu-item {
  display: flex;
  background-color: #6797c870;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 495px;
  height: auto;
}
.menu-item img {
  width: 115px;
  height: 100px;
  max-height: 200px;
  object-fit: fill;
  border-radius: 8px;
  margin-bottom: -3px;
}
.menu-item > div {
    

    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 9px;
    margin-left: 9px;
}

.menu-list-text-buttons{
  display: flex;
  flex-direction: column;
}

.menu-item .button{
  display: flex;
  margin-right: auto;
  margin-top: 10px;
  
}

.menu-item h2 {
  font-size: 20px;
  color: #ffffff;
  font-family: "Times New Roman", Times, serif;
  margin-top: -9px;
  display: flex;
  text-align: left;
}
.menu-item span {
  font-size: 15px;
  color: #c7e7ed;
  font-weight: 600;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.5;
  white-space: nowrap; /* Satır sonu dönüşü engelle */
  overflow: hidden; /* Taşan içeriği gizle */
  text-overflow: ellipsis; /* Taşan içeriğin yerine üç nokta koy */

}
.menu-item p {
  font-size: 14px;
  color: #000000;
  font-style: bold;
  font-weight: 700;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-style: oblique;
  line-height: 1.5;
  white-space: nowrap; /* Satır sonu dönüşü engelle */
  overflow: hidden; /* Taşan içeriği gizle */
  text-overflow: ellipsis; /* Taşan içeriğin yerine üç nokta koy */
  margin-top: -13px;
}

.menu-youtube {
  padding: 12px 0;
  max-width: 60px;
  max-height: 60px;
  margin-left: 45px;
  color: #fff;
  border: none;
  border-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.menu-sepet {
  margin-right: 0px;
  max-width: 50px;
  max-height: 50px;
  margin-left: auto;

  border: none;
  border-radius: 50%;
  gap: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.menu-list-texts{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: start;
}

.menu-item button:hover {
  background-color: #0056b3;
}

.menu-image {
  width: 100%;
  height: auto;
  border-top: 1px solid #ddd;
}
.button {
  display: flex;
  align-items: center;
}

.button input[type="number"] {
  width: 50px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.button img {
  margin-left: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
@media screen and (max-width: 768px){
  .menu-item {
     width: 650px;
  }
}
@media screen and (max-width: 560px){
  .menu-item {
     width: 400px;
  }
}
@media screen and (max-width: 430px){
  .menu-item {
     width: 335px;
  }
}