h1,
h2 {
  color: #333;
}

.masa-siparis-container-admin {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #6c90b6;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.masa-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.masa {
  width: 120px;
  height: 120px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: #555;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, background-color 0.3s ease;
}

.masa:hover {
  transform: translateY(-5px);
  background-color: #f0f0f0;
}

.modal {
  width: 600px;
  height: auto;
  background-color: #fff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.order-details {
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-height: 300px;
}

.order-item {
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  border: 2px solid #ccc;
}
.latest-paid {
  display: flex;
  justify-content: space-around;
}
.order-item:hover {
  background-color: #f0f0f0;
}

.latest-order-container,
.paid-orders-container {
  max-height: 350px;
  overflow-y: auto;
  padding: 15px;
  border: 1px solid #ddd;
  background-color: #fdfdfd;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  width: 450px;
  margin-top: 15px;
  margin-bottom: 20px;
}
.latest-order-container h2,
.paid-orders-container h2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.total-amount {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #218838;
}
.siparisiAl-kapat-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.edit-order-modal input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s ease;
}

.edit-order-modal input:focus {
  border-color: #28a745;
  outline: none;
}
.print-header {
  display: none;
}
.footer {
  display: none;
}
.print-titles {
  display: none;
}
@media print {
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    font-size: 14px;
  }

  /* Fiş başlık kısmı */
  .print-header {
    display: block;
    text-align: center;
    margin: 0;

    font-size: 16px;
    font-weight: bold;
    /* Altına çizgi ekleyerek başlığı ayır */
  }

  /* Fişteki her sipariş detayına stil verme */
  .order-details {
    page-break-inside: avoid; /* Sayfa kırılmalarında içerik bölünmesin */
    max-height: max-content;
  }

  .order-item {
    display: flex;
    flex-wrap: wrap; /* Taşma durumunda alt satıra geçsin */
    justify-content: space-between; /* Öğeleri yatayda yayarak hizala */
    align-items: center; /* Dikeyde ortalar */
    margin: 0; /* Dış boşlukları tamamen kaldır */
    padding: 0; /* İç boşlukları tamamen kaldır */
    width: 100%; /* Tüm genişliği kullan */
    box-sizing: border-box; /* Genişliğin dışına taşmasını engelle */
  }
  
  .order-item p {
    margin: 0; /* Paragraf etiketlerindeki boşlukları kaldır */
    padding: 0; /* İç boşlukları kaldır */
    
    box-sizing: border-box; /* Genişlikten taşmamasını sağlar */
  }
  
  .product-name {
    flex-basis: 40%; /* Ürün adı için daha geniş bir alan ayırdık */
    font-weight: bold;
    margin-left: 0; /* Sol taraftan boşluk olmasın */
  }
  
  .product-price {
    flex-basis: 20%; /* Toplam fiyat için belirli bir genişlik */
  }
  .product-quantity {
    flex-basis: 20%; /* Toplam fiyat için belirli bir genişlik */
  }
  .order-detail-span {
    display: none;
  }
  .order-item p strong {
    margin-top: 0px;
    font-weight: bold;
  }
  /* Kategori ve notları yazdırma sırasında gizle */
  .order-category,
  .order-note {
    display: none;
  }
  .print-titles {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }

  .print-title1,
  .print-title2,
  .print-title3 {
    margin-bottom: 10px;
    /* Sütunlar arasına boşluk ekle */
    /* Her sütunun genişliğini ayarladık */
    box-sizing: border-box; /* Padding'in genişlikten taşmamasını sağlar */
  }
  .print-title1 {
    flex-basis: 60%;
    font-weight: bold;
  }

  .print-title2 {
    flex-basis: 20%;
  }
  .print-title3 {
    flex-basis: 20%;
  }
  
  button {
    display: none; /* Tüm butonları gizler */
  }
  .selectedMasa {
    display: none; /* Tüm butonları gizler */
  }
  .footer {
    display: block;
    text-align: center;

    font-size: 12px;
  }
  /* Toplam ödeme ve garson bilgisi */
  .total-amount {
    text-align: center;

    background-color: #f8f8f8;

    font-size: 16px;
    font-weight: bold;
  }
  .total-amount p {
    display: none;
  }
}
