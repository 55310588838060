.admin-container {
  max-width: 400px;
  margin: 0 auto; /* Sayfanın en ortasına hizalamak için */
  margin-top: 150px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #fff;
  background-color: rgba(0, 0, 0, 0.581);
}

.admin-container h2 {
  margin-bottom: 20px;
}

.admin-container form {
  display: flex;
  flex-direction: column;
}

.admin-container label {
  margin-bottom: 10px;
}

.admin-container input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 20px); /* inputların genişliği */
}

.admin-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-container button:hover {
  background-color: #0056b3;
}

.error-message-admin {
  color: #ff0000;
  margin-top: 10px;
}
