.admin-menu-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.admin-menu-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

form {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="number"],
select,
textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input[type="file"] {
  display: block;
}
.input-update-delete{
  display: flex;
  flex-direction: column;
}
.update-delete{
  display: flex;
  justify-content: space-between;
}

.delete-button {
  background-color: #ff0000;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
  width: 100px;
  transition: background-color 0.3s, transform 0.2s;
}
button {
  background-color: #0056b3;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.update-price{
  background-color: #4c9f50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 137px;
  transition: background-color 0.3s, transform 0.2s;
}

button:hover {
  background-color: #0056b3;
  
}

.language-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.language-buttons button {
  margin: 0 5px;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  background-color: white;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

ul li img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 15px;
}

ul li div {
  flex-grow: 1;
}

ul li div h3 {
  margin: 0 0 10px 0;
  font-size: 20px;
  color: #333;
}

ul li div p {
  margin: 2px 0;
  font-size: 16px;
  color: #666;
}

ul li button {
  margin-top: 10px;
}

ul li input[type="number"] {
  width: 80px;
  margin-right: 10px;
}
