body {
  font-family: "Lora", serif;
  overflow-x: hidden;
  background-color: #3c6085;
  /*background-image: url("../images/background2.jpg");*/
  background-size: cover; /* Cover the entire screen */
  background-repeat: no-repeat; /* No repeating */
  background-position: center;
}

.welcome-panel {
  width: 450px;
  height: 700px;
  margin: 100px auto;
  padding: 20px;
  background-color: rgba(103, 151, 200, 0.64); /* Slightly transparent */
  border: 1px solid #ced4da;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); /* Softer shadow */



}

.welcome-panel-logo {
  width: 80%;
  border-radius: 8px;
}

.language-select {
  text-align: center;
  margin-bottom: 20px;
}

.language-flag-buttons {
  display: flex;
  justify-content: center;
  gap: 12px; 
  margin-top: 10px;
}

.flag-icon-container {
  width: 40px; 
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flag-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.flag-icon-container:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); 
}

.flag-icon-container:active {
  transform: scale(1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.welcome-panel h1 {
  color: #000;
  font-size: 36px; 
  margin-bottom: 20px;
}

.welcome-panel p {
  color: #f0f0f0;
  font-size: 18px;
  margin-bottom: 15px;
}

select,
button {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

select {
  appearance: none;
}

.welcome-panel button {
  background-color: #002641;
  color: #fff;
  transition: background-color 0.3s ease;
}

.button-masa button:hover {
  background-color: #45a049; /* Mouse üzerine gelindiğinde arka plan renginin değişimi */
}

.masa-error-message {
  background-color: #862727; /* Enhanced contrast for readability */
  color: #fff; /* White text for better visibility */
  font-size: 14px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px; /* Rounded corners for the error message */
}

@media screen and (max-width: 1024px) {
  .welcome-panel {
    width: auto;
    height: auto;
    margin: 100px auto;
    padding: 20px;
    background-color: rgba(103, 151, 200, 0.64); /* Slightly transparent */
    border: 1px solid #ced4da;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); /* Softer shadow */
  
  
  
  }
}